import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import { ReactComponent as FacebookIcon } from "../../../assets/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/instagram.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/google.svg";
import { ReactComponent as BBBIcon } from "../../../assets/bbb.svg";

import "../../../styles/components/pages/reviews.scss";

function Reviews() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="about"
      selectedLevel2="reviews"
      className="generic-page reviews"
      title="Reviews"
      description="Learn more about what our clients are saying about their experience at Deason Garner & Hansen Law Firm"
    >
      <div className="page-content">
        <h1>Read our Reviews</h1>

        <h2>Leave us a Review!</h2>
        <div className="d-flex">
          <div
            className="platform ms-0"
            onClick={() => window.open("https://g.page/DeasonGarnerLaw?share")}
          >
            <GoogleIcon style={{ color: "#4285F4" }} />
            <span className="mt-3">Google</span>
          </div>
          <div
            className="platform"
            onClick={() =>
              window.open("https://www.facebook.com/DeasonGarnerLaw")
            }
          >
            <FacebookIcon style={{ color: "#4267B2" }} />
            <span className="mt-3">Facebook</span>
          </div>
          <div
            className="platform"
            onClick={() =>
              window.open(
                "https://www.bbb.org/us/az/yuma/profile/lawyers/dgh-law-firm-1126-1000033420"
              )
            }
          >
            <BBBIcon />
            <span className="mt-3">BBB</span>
          </div>
          <div
            className="platform"
            onClick={() => window.open("https://www.instagram.com/dghlawfirm/")}
          >
            <InstagramIcon style={{ color: "#E1306C" }} />
            <span className="mt-3">Instagram</span>
          </div>
        </div>

        <h2>What our clients are saying</h2>
        <div className="rounded box-shadow p-5">
          <p>
            Have been working with this firm on the administration of my
            mother's trust which was old and outdated. They have been with us
            every step of the way guiding us with professional, and
            knowledgeable help. Always willing to answer any questions we have
            had in detail. They have made a stressful time so much easier. A
            special thank you to Janet Leon for her attention to all the details
          </p>
          <p>Cheryl S.</p>
        </div>

        <div className="rounded box-shadow p-5">
          <p>
            Really happy with the services rendered by Deason Garner & Hansen in
            the preparation of my mother's trust. Their attention to detail,
            responsiveness, & customer service is second to NONE!!! Miranda
            Ochoa was a "superstar" in guiding our family throughout the entire
            trust building process.
          </p>
          <p>Russell M.</p>
        </div>

        <div className="rounded box-shadow p-5">
          <p>
            Online seminar was great. It was very informative and I recommend
            for everyone to watch.
          </p>
          <p>Krystal C.</p>
        </div>

        <div className="rounded box-shadow p-5">
          <p>
            GREAT – HONEST- I highly recommend this place. They are professional
            and courtesy. As a insurance agent with deal customer service. Janet
            Leon helped us with a mutual customer and it was a pleasure working
            with her. She responded to my emails in a timely manner as well.
          </p>
          <p>Veronica C.</p>
        </div>

        <div className="rounded box-shadow p-5">
          <p>
            Staff is incredible! Not only do they show a graceful form of
            professionalism but their knowledge really shines! A special thank
            you to Janet Leon who truly goes above and beyond to provide the
            best possible service. My experience would not have been half as a
            pleasurable without her! All in all an amazing firm!
          </p>
          <p>Marly M.</p>
        </div>
      </div>
    </Page>
  );
}

export default Reviews;
