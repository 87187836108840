import React from "react";

import { ReactComponent as FacebookIcon } from "../../assets/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/instagram.svg";
import { ReactComponent as GoogleIcon } from "../../assets/google.svg";

function Social({ darkmode }) {
  const modeClass = darkmode ? "white" : "";

  return (
    <div className="social flex-row-aligned">
      <span
        className={modeClass}
        onClick={() => window.open("https://www.facebook.com/DeasonGarnerLaw")}
      >
        <FacebookIcon className="pointer" style={{ width: "20px" }} />
      </span>
      <span
        className={`ms-1 ${modeClass}`}
        onClick={() => window.open("https://www.instagram.com/dghlawfirm/")}
      >
        <InstagramIcon className="pointer" style={{ width: "20px" }} />
      </span>
      <span
        className={`ms-1 ${modeClass}`}
        onClick={() => window.open("https://g.page/DeasonGarnerLaw?share")}
      >
        <GoogleIcon className="pointer" style={{ width: "20px" }} />
      </span>
      <span
        className={`ms-1 pointer ${modeClass}`}
        onClick={() =>
          window.open(
            "https://www.bbb.org/us/az/yuma/profile/lawyers/dgh-law-firm-1126-1000033420"
          )
        }
      >
        BBB
      </span>
    </div>
  );
}

export default Social;
