import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import savannah from "../../../assets/savannah.jpeg";
import danielle from "../../../assets/danielle.webp";
import briana from "../../../assets/briana.webp";
import jazlyn from "../../../assets/jazlyn.webp";
import jordana from "../../../assets/jordana.webp";
import lana from "../../../assets/lana.webp";
import alyza from "../../../assets/alyza.webp";
import tayde from "../../../assets/tayde.webp";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";
import "../../../styles/components/pages/meet-our-team.scss";

function MeetOurTeam() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="about"
      selectedLevel2="meetOurTeam"
      className="generic-page"
      title="Meet Our Team"
      description="We are dedicated to excellence in all that we do. Learn more about our amazing team members."
    >
      <div className="page-content meet-our-team">
        <h1>Meet Our Team</h1>

        <div className="team-member">
          <img src={savannah} alt="Savannah Soto, Office Manager" />
          <div>
            <h2>Savannah Soto</h2>
            <p className="title">Office Manager</p>
            <p>
              Savannah joined the Deason Garner & Hansen Law Firm in March of
              2019. With over eleven years of experience in the legal field, she
              has a wealth of knowledge to share with our clients. Savannah has
              a bachelor's degree in business administration with an emphasis in
              organizational management. She is also the designated real estate
              broker for our sister company, DGH Realty.
            </p>
            <p>
              When she is not in the office she enjoys traveling, helping her
              clients buy and sell homes, going out to the shooting range with
              her husband and fishing.
            </p>
            <a href="mailto:Savannah@DeasonGarnerLaw.com">
              Savannah@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={danielle} alt="Danielle Avila, Estate Planning Paralegal" />
          <div>
            <h2>Danielle Avila</h2>
            <p className="title">Estate Planning Paralegal</p>
            <p>
              Danielle assists our clients with the preparation of their legal
              documents. When not at the office, Danielle is currently attending
              nursing school.
            </p>
            <p>
              A Yuma native, Danielle likes to spend time with her friends,
              family and especially her five-year-old daughter.
            </p>
            <a href="mailto:Danielle@DeasonGarnerLaw.com">
              Danielle@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={briana} alt="Briana Greenidge, Estate Planning Paralegal" />
          <div>
            <h2>Briana Greenidge</h2>
            <p className="title">Estate Planning Paralegal</p>
            <p>
              Briana joined Deason Garner & Hansen Law Firm in September 2017.
              She assists our clients with the preparation and execution of
              their legal documents. Briana has a bachelor's in business
              administration with an emphasis in marketing.
            </p>
            <p>
              When she is out of the office she loves spending time with her
              husband and fur babies.
            </p>
            <a href="mailto:Briana@DeasonGarnerLaw.com">
              Briana@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={alyza} alt="Alyza Ruiz, Estate Planning Paralegal" />
          <div>
            <h2>Aylza Ruiz</h2>
            <p className="title">Estate Planning Paralegal</p>
            <p>
              Aylza joined Deason Garner & Hansen Law Firm in April 2021. She
              assists our clients with the preparation and execution of their
              legal documents.
            </p>
            <p>
              In her spare time, she enjoys spending time with friends, family
              and her dog Kylo.
            </p>
          </div>
        </div>

        <div className="team-member">
          <img src={jordana} alt="Jordana Canela, Estate Planning Paralegal" />
          <div>
            <h2>Jordana Canela</h2>
            <p className="title">Estate Planning Paralegal</p>
            <p>
              Jordana assists our clients with the preparation and execution of
              their estate planning, as well as updates to their business
              planning. She was born and raised here in Yuma, Arizona. When she
              is not at work, she enjoys spending time with her friends and
              family.
            </p>
          </div>
        </div>

        <div className="team-member">
          <img
            src={jazlyn}
            alt="Jazlyn Castro, Trust Administration & Probate Paralegal"
          />
          <div>
            <h2>Jazlyn Castro</h2>
            <p className="title">Trust Administration & Probate Paralegal</p>
            <p>
              Jazlyn joined the Deason Garner & Hansen Law Firm in June 2020.
              She assists the attorneys with trust administration and probate
              matters. Jazlyn is also a Real Estate Agent for DGH Realty.
            </p>
            <p>
              Jazlyn enjoys spending time with her family when not in the
              office.
            </p>
            <a href="mailto:Jazlyn@DeasonGarnerLaw.com">
              Jazlyn@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img
            src={lana}
            alt="Lana Leon-Guerrero, Trust Administration and Probate Paralegal"
          />
          <div>
            <h2>Lana Leon-Guerrero</h2>
            <p className="title">Trust Administration and Probate Paralegal</p>
            <p>
              Lana joined the Deason Garner & Hansen Law Firm in March 2021. She
              assists the attorneys with trust administration and probate
              matters. Lana is also a Real Estate Agent for DGH Realty. She
              comes from a versatile work background and has a masters of art
              degree in education.
            </p>
            <p>
              Lana's favorite pastimes are hiking, camping and exploring with
              her husband and furry children.
            </p>
          </div>
        </div>

        <div className="team-member">
          <img src={tayde} alt="Tayde Lopez, Director of First Impressions" />
          <div>
            <h2>Tayde Lopez</h2>
            <p className="title">Director of First Impressions</p>
            <p>
              Tayde assists the attorneys and estate planning paralegals with
              the preparation of information to get the process started for our
              clients' estate planning documents. She takes joy in assisting
              clients in their every estate planning need.
            </p>
            <p>
              In her spare time she attends Arizona Western College and enjoys
              spending time with her family.
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default MeetOurTeam;
